import { ReactNode, useDeferredValue, useEffect, useMemo, useRef, useState } from 'react'
import { Box, Table, TableContainer, Typography } from '@mui/material'
import EcertTableHead from './EcertTableHead'
import EcertTableBody from './EcertTableBody'
import StyleGuide from '../../styles/StyleGuide'
import { useTranslation } from 'react-i18next'
import LoadingIndicator from '../loading-indicator/LoadingIndicator'

export interface IColumn {
  label: string | ReactNode
  accessor: string | ((data: any, index?: number) => any)
  role?: string
  sortable?: boolean
  sortFn?: (a, b) => number
  idAccessor?: string
  sortByOrder?: 'asc' | 'desc'
  tooltip?: string
  editable?: boolean
  transformFn?: (data: any) => string | ReactNode
  accessibilityHrefFn?: (data: any) => string | null
  whenEditingFn?: (data: any, index) => ReactNode
  whenCreatingFn?: (data: any, index?) => ReactNode
}

export interface IVirtualScrollSettings {
  rowsToDisplay: number
  rowsToIncrement: number
}

type ViewMode = 'EDIT' | 'VIEW'
interface IEcertTableProps {
  data: any[] | undefined
  columns: IColumn[]
  unfilteredResultsLength?: number
  ariaDescribedBy?: string
  onRowClick?: (row: any) => void
  mode?: ViewMode
  rowBackgroundColorFn?: (data: any) => string
  virtualScrollSettings?: IVirtualScrollSettings
  customNoDataHeight?: string
  noDataString?: string
  tableResultString?: JSX.Element
}

export default function EcertTable({
  data,
  columns,
  unfilteredResultsLength,
  ariaDescribedBy,
  onRowClick,
  mode = 'VIEW',
  rowBackgroundColorFn,
  virtualScrollSettings = {
    rowsToDisplay: 60,
    rowsToIncrement: 60
  },
  customNoDataHeight = '400px',
  noDataString,
  tableResultString
}: IEcertTableProps) {
  const [sortColumn, setSortColumn] = useState(() => getInitialSortColumn(columns))
  const [order, setOrder] = useState<'asc' | 'desc'>(() => getInitialSortOrder(columns))
  const [tableData, setTableData] = useState(() => (data === undefined ? undefined : initialSortedData(data, columns)))
  const { t } = useTranslation()
  const tableContainer = useRef(null)
  const [virtualizedScrollSettings, setVirtualizedScrollSettings] =
    useState<IVirtualScrollSettings>(virtualScrollSettings)
  const virtualizedScrollSettingsDeferred = useDeferredValue(virtualizedScrollSettings)
  const previousDistanceFromBottom = useRef<number>(Number.MAX_VALUE)

  useEffect(() => {
    const tableScrollHandler = () => {
      const { scrollHeight, scrollTop, clientHeight } = document.documentElement
      const distanceFromBottom = scrollHeight - scrollTop - clientHeight

      if (
        previousDistanceFromBottom.current > 100 &&
        distanceFromBottom <= 100 &&
        virtualizedScrollSettings.rowsToDisplay < tableData?.length!
      ) {
        setVirtualizedScrollSettings((prevState) => {
          const newState = { ...prevState }
          newState.rowsToDisplay += newState.rowsToIncrement
          return newState
        })
      }
      previousDistanceFromBottom.current = distanceFromBottom
    }

    if ((tableData?.length || 0) > 0) {
      window.addEventListener('scroll', tableScrollHandler)

      return () => window.removeEventListener('scroll', tableScrollHandler)
    }
  }, [tableData, virtualizedScrollSettings.rowsToDisplay])

  useEffect(() => {
    handleSorting(sortColumn, order)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortColumn, order, data])

  const handleSorting = (sortColumn: IColumn | null, sortOrder: 'asc' | 'desc') => {
    let sorted = data === undefined ? undefined : [...data]
    if (sorted && sortColumn) {
      sorted = sortData(sorted, sortColumn, sortOrder)
    }
    setTableData(sorted)
  }

  const EcertTableBodyMemoized = useMemo(() => {
    return (
      <EcertTableBody
        virtualizedScrollSettings={virtualizedScrollSettings}
        columns={columns}
        tableData={tableData || []}
        onRowClick={onRowClick}
        mode={mode}
        rowBackgroundColorFn={rowBackgroundColorFn}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [virtualizedScrollSettingsDeferred, tableData])

  return (
    <TableContainer ref={tableContainer} sx={{ overflowX: 'visible' }}>
      {unfilteredResultsLength !== undefined && (
        <Box aria-live="polite">
          {tableResultString
            ? tableResultString
            : t('table.results', { results: tableData?.length, unfilteredResults: unfilteredResultsLength })}
        </Box>
      )}
      {tableData && tableData.length > 0 && (
        <Table aria-describedby={ariaDescribedBy} stickyHeader aria-label={!ariaDescribedBy ? 'ecert-table' : ''}>
          <EcertTableHead
            columns={columns}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
            sortOrder={order}
            setSortOrder={setOrder}
          ></EcertTableHead>
          {EcertTableBodyMemoized}
        </Table>
      )}

      {tableData?.length === 0 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            height: customNoDataHeight,
            backgroundColor: StyleGuide.constants.COLOR_SECONDARY_LIGHT_4
          }}
        >
          <Typography variant="body1">{noDataString ? noDataString : t('table.dataNotFound')}</Typography>
        </Box>
      )}

      {tableData === undefined && <LoadingIndicator />}
    </TableContainer>
  )
}

const getInitialSortColumn = (columns: IColumn[]): IColumn | null => {
  return columns.find((col) => col.sortByOrder !== undefined) || null
}

const getInitialSortOrder = (columns: IColumn[]): 'asc' | 'desc' => {
  return columns.find((col) => col.sortByOrder !== undefined)?.sortByOrder || 'asc'
}

const initialSortedData = (defaultTableData: any[], columns: IColumn[]): any[] => {
  const sortColumn = columns.find((column) => column.sortByOrder)
  if (!sortColumn) return defaultTableData
  return sortData(defaultTableData, sortColumn)
}

const sortData = (data: any[], column: IColumn, sortOrder?: string) => {
  // if sortFn is provided, just sort the data with that
  if (column.sortFn) {
    if (sortOrder === 'asc') {
      return data.sort(column.sortFn)
    } else if (sortOrder === 'desc') {
      return data.sort(column.sortFn).reverse()
    }
  }

  // otherwise, sort based on the accessed data
  if (!column || typeof column.accessor !== 'string') return data

  const accessor: string = column.accessor
  return [...data].sort((a, b) => {
    if (a[accessor] === null) return 1
    if (b[accessor] === null) return -1
    if (a[accessor] === null && b[accessor] === null) return 0

    // eslint-disable-next-line
    if (!a[accessor]) return

    const transformedA = column.transformFn ? column.transformFn(a[accessor]) : a[accessor]
    const transformedB = column.transformFn ? column.transformFn(b[accessor]) : b[accessor]

    const ascending = transformedA.toString().localeCompare(transformedB.toString(), 'fi', {
      numeric: true
    })

    return (sortOrder || column.sortByOrder) === 'asc' ? ascending : -ascending
  })
}
