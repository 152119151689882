import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ECERT_API } from '../../../services/ecert-api'
import EcertTable, { IColumn } from '../../../features/table/EcertTable'
import DownloadButton from '../../../features/buttons/DownloadButton'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { Typography } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { SignedDocumentBatchDto } from '../../../api/types'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { useLoginUser } from '../../../LoginContext'
import { canViewOrganizations } from '../../../utils/permission-utils'
import OrganizationSelect from '../../../features/organization-select/OrganizationSelect'
import { useOrganizations } from '../../../features/store-slices/organizationsSlice'
import { EcertDateRangePicker } from '../../../features/forms/date-range-picker/EcertDateRangePicker'

type Filter = {
  organizationId: number | null
  signingTimeStart: Dayjs | null
  signingTimeEnd: Dayjs | null
}

export function SignedDocuments() {
  const { t } = useTranslation()
  const loginUser = useLoginUser()
  const [organizations, fetchOrganizations] = useOrganizations(false)
  const [filter, setFilter] = useState<Filter>({
    organizationId: canViewOrganizations(loginUser) ? null : loginUser.organizationId!,
    signingTimeStart: null,
    signingTimeEnd: null
  })
  const [documentBatches, setDocumentBatches] = useState<SignedDocumentBatchDto[]>()

  useEffect(() => {
    document.title = `Ecert - ${t('title.signedDocuments')}`
  }, [t])

  useEffect(() => {
    if (canViewOrganizations(loginUser)) {
      fetchOrganizations()
    }
  }, [loginUser, fetchOrganizations])

  useEffect(() => {
    setDocumentBatches(undefined)
    ECERT_API.getSignedDocumentBatches(filter.organizationId).then((response) => setDocumentBatches(response.data))
  }, [filter.organizationId])

  const updateFilter = (update: Partial<Filter>) => {
    setFilter({ ...filter, ...update })
  }

  const matchesFilter = (value: SignedDocumentBatchDto) => {
    if (filter.signingTimeStart && filter.signingTimeStart.isAfter(value.completedDate)) {
      return false
    }
    if (filter.signingTimeEnd && filter.signingTimeEnd.isBefore(value.completedDate)) {
      return false
    }
    return true
  }

  const columns: IColumn[] = [
    {
      label: t('signedDocumentsReport.signingDate'),
      accessor: (data: SignedDocumentBatchDto) => dayjs(data.completedDate).format('DD.MM.YYYY'),
      role: 'rowheader'
    },
    {
      label: t('signedDocumentsReport.documentCount'),
      accessor: (data: SignedDocumentBatchDto) => data.documentCount
    },
    {
      label: t('signedDocumentsReport.transactionPeriod'),
      accessor: (data: SignedDocumentBatchDto) =>
        [
          dayjs(data.transactionPeriodStart).format('DD.MM.YYYY HH:mm'),
          dayjs(data.transactionPeriodEnd).format('DD.MM.YYYY HH:mm')
        ].join(' - ')
    },
    {
      label: t('common.functions'),
      accessor: (data: SignedDocumentBatchDto) => {
        return (
          <DownloadButton
            fileName={`${t('signedDocumentsReport.downloadFilename')}_${data.esignOperationId!}.zip`}
            fileUrl={ECERT_API.FILE_DOWNLOAD_URLS.SIGNED_DOCUMENTS(filter.organizationId, data.esignOperationId!)}
            startIcon={<FileDownloadOutlinedIcon />}
          >
            {t('signedDocumentsReport.download')}
          </DownloadButton>
        )
      }
    }
  ]

  return (
    <FlexColumn gap="20px">
      <Typography variant="h1">{t('signedDocumentsReport.title')}</Typography>
      <FlexRow gap="20px" justifyContent="stretch" alignItems="flex-end">
        {canViewOrganizations(loginUser) && organizations.length > 0 && (
          <OrganizationSelect
            value={organizations.find((org) => org.id === filter.organizationId) ?? null}
            organizations={organizations}
            onSelect={(organization) => {
              updateFilter({ organizationId: organization ? organization.id! : null })
            }}
          />
        )}
        <EcertDateRangePicker
          clearable={true}
          label={t('signedDocumentsReport.signingDateFilter')}
          defaultValue={{}}
          onUpdateStart={(date) => updateFilter({ signingTimeStart: date })}
          onUpdateEnd={(date) => updateFilter({ signingTimeEnd: date })}
        />
      </FlexRow>
      <EcertTable
        ariaDescribedBy="title-report-signed-documents"
        data={documentBatches?.filter((value) => matchesFilter(value))}
        columns={columns}
      ></EcertTable>
    </FlexColumn>
  )
}
