import { useDeferredValue, useEffect, useMemo, useState } from 'react'
import { Box, Button, InputLabel, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ToggleLocked } from '../../features/toggle-locked/ToggleLocked'
import { useProductionDevices } from '../../features/store-slices/productionDevicesSlice'
import AddIcon from '@mui/icons-material/Add'
import { FileExcelOutline } from 'mdi-material-ui'
import { OrganizationListItemDto, ProductionDeviceListViewDto } from '../../api/types'
import EcertTable, { IColumn } from '../../features/table/EcertTable'
import { ECERT_API } from '../../services/ecert-api'
import DownloadButton from '../../features/buttons/DownloadButton'
import { getTranslation, parseDate, transformDate } from '../../utils/utils'
import { EcertRoutes } from '../../ecert-routes'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FlexColumn, FlexRow } from '../../styles/containers/FlexContainers'
import { canCreateProductionDevices, canViewOrganizations, singleOrganization } from '../../utils/permission-utils'
import OrganizationSelect from '../../features/organization-select/OrganizationSelect'
import { useOrganizations } from '../../features/store-slices/organizationsSlice'
import { useLoginUser } from '../../LoginContext'

export default function ProductionDevices() {
  const loginUser = useLoginUser()
  const [searchParams, setSearchParams] = useSearchParams()
  const [productionDevices] = useProductionDevices(
    true,
    true,
    singleOrganization(searchParams.get('organizationId'), loginUser)
  )
  const { t } = useTranslation()
  const [organizations, fetchOrganizations] = useOrganizations(false)
  const [showLocked, toggleLocked] = useState(false)
  const showLockedDeferred = useDeferredValue(showLocked)
  const [filterText, setFilterText] = useState('')
  const filterTextDeferred = useDeferredValue(filterText)
  const navigate = useNavigate()
  const [selectedOrganization, setSelectedOrganization] = useState<OrganizationListItemDto | null>(null)

  const columns: IColumn[] = [
    {
      label: t('productionDevice.name'),
      accessibilityHrefFn: (obj: ProductionDeviceListViewDto) =>
        EcertRoutes.productionDevice(obj.organizationId, obj.id),
      accessor: 'name',
      sortable: true,
      sortByOrder: 'asc',
      role: 'rowheader'
    },
    {
      label: t('productionDevice.validity.status'),
      accessor: (data: ProductionDeviceListViewDto) => data,
      transformFn: (data: ProductionDeviceListViewDto) => {
        return `${getTranslation(`productionDevice.status.${data.status!.toLowerCase()}`)} ${
          data.statusOfDraft && data.statusOfDraft !== data.status
            ? `+ ${getTranslation(`productionDevice.status.${data.statusOfDraft.toLowerCase()}`)}`
            : ''
        }`
      },
      sortable: true,
      sortByOrder: 'asc',
      sortFn: (a: ProductionDeviceListViewDto, b: ProductionDeviceListViewDto) => {
        const status = { APPROVED: 1, DRAFT: 2, WAITING_APPROVAL: 3, RETURNED: 4, REFUSED: 5 }
        if (status[a.status!] < status[b.status!]) return -1
        if (status[a.status!] > status[b.status!]) return 1

        return 0
      }
    },
    {
      label: t('productionDevice.gsrn'),
      accessor: 'gsrn',
      sortable: true,
      sortByOrder: 'asc'
    },
    {
      label: t('productionDevice.capacity'),
      accessor: 'capacity',
      sortable: true,
      sortByOrder: 'asc'
    },
    {
      label: t('productionDevice.production.technology'),
      accessor: 'technologyTypeName',
      sortable: true,
      sortByOrder: 'asc'
    },
    {
      label: t('productionDevice.validity.expirationDate'),
      accessor: 'validityEndDate',
      transformFn: transformDate('DD.MM.YYYY'),
      sortable: true,
      sortByOrder: 'asc',
      sortFn: (a: ProductionDeviceListViewDto, b: ProductionDeviceListViewDto) => {
        if (!a.validityEndDate) return 1
        if (!b.validityEndDate) return -1
        return parseDate(a.validityEndDate).isBefore(parseDate(b.validityEndDate), 'day') ? -1 : 1
      }
    },
    {
      label: t('productionDevice.validity.poaExpirationDate'),
      accessor: 'poaValidityEndDate',
      transformFn: transformDate('DD.MM.YYYY'),
      sortable: true,
      sortByOrder: 'asc',
      sortFn: (a: ProductionDeviceListViewDto, b: ProductionDeviceListViewDto) => {
        if (!a.poaValidityEndDate) return 1
        if (!b.poaValidityEndDate) return -1
        return parseDate(a.poaValidityEndDate).isBefore(parseDate(b.poaValidityEndDate), 'day') ? -1 : 1
      }
    },
    {
      label: t('productionDevice.accountHolderName'),
      accessor: 'organizationName',
      sortable: true,
      sortByOrder: 'asc'
    },
    {
      label: t('productionDevice.deviceOwners'),
      accessor: 'owners',
      sortable: true,
      sortByOrder: 'asc'
    }
  ]

  useEffect(() => {
    if (canViewOrganizations(loginUser)) {
      fetchOrganizations()
    }
  }, [loginUser, fetchOrganizations])

  useEffect(() => {
    document.title = `Ecert - ${t('title.productionDeviceList')}`
  }, [t])

  useEffect(() => {
    const organizationId = searchParams.get('organizationId')
    setSelectedOrganization(organizations?.find((org) => org.id === +organizationId!) || null)
  }, [searchParams, organizations])

  const handleProductionDeviceClick = (productionDevice: ProductionDeviceListViewDto) => {
    navigate(EcertRoutes.productionDevice(productionDevice.organizationId, productionDevice.id))
  }

  const handleCreateProductionDevice = () => {
    navigate(EcertRoutes.newProductionDevice(loginUser.organizationId))
  }

  const filterDevices = (productionDevices: ProductionDeviceListViewDto[]) => {
    return productionDevices
      .filter((d) => (selectedOrganization ? d.organizationId === selectedOrganization.id : true))
      .filter((d) => (showLockedDeferred ? d.locked : !d.locked))
      .filter(
        (d) =>
          d.name?.toLocaleLowerCase().includes(filterTextDeferred.toLocaleLowerCase()) ||
          d.gsrn?.toLocaleLowerCase().includes(filterTextDeferred.toLocaleLowerCase())
      )
  }

  const handleOrganizationSelect = (organization: OrganizationListItemDto | null) => {
    setSelectedOrganization(organization)
    setSearchParams(organization ? `?${new URLSearchParams({ organizationId: `${organization?.id}` })}` : '')
  }

  const getOrganizationNumberForExport = () => {
    return selectedOrganization ? +selectedOrganization.id! : 0
  }

  const EcertTableDeferred = useMemo(() => {
    return (
      <EcertTable
        unfilteredResultsLength={productionDevices?.length}
        ariaDescribedBy="title-production-devices"
        data={productionDevices ? filterDevices(productionDevices) : undefined}
        columns={columns}
        onRowClick={handleProductionDeviceClick}
      />
    )
    // eslint-disable-next-line
  }, [productionDevices, showLockedDeferred, filterTextDeferred, selectedOrganization])

  return (
    <FlexColumn gap="8px">
      <FlexRow justifyContent="space-between" alignItems="center">
        <FlexRow gap="8px">
          <Typography id="title-production-devices" variant="h1">
            {t('title.productionDeviceList')}
          </Typography>
          <ToggleLocked showLocked={showLocked} toggleLocked={() => toggleLocked(!showLocked)} />
        </FlexRow>
        <FlexRow gap="8px" alignItems="flex-end">
          {canViewOrganizations(loginUser) && (
            <OrganizationSelect
              value={selectedOrganization}
              organizations={organizations || []}
              onSelect={handleOrganizationSelect}
            />
          )}
          <Box>
            <InputLabel htmlFor="filter">{t('productionDevice.list.filter.name')}</InputLabel>
            <TextField
              id="filter"
              variant="outlined"
              value={filterText}
              InputProps={{
                sx: { height: '50px' }
              }}
              onChange={(event) => setFilterText(event.target.value)}
            ></TextField>
          </Box>
          <DownloadButton
            fileUrl={ECERT_API.FILE_DOWNLOAD_URLS.PRODUCTION_DEVICES(
              canViewOrganizations(loginUser) ? +getOrganizationNumberForExport() : loginUser.organizationId!
            )}
            fileName="productionDevices.xlsx"
            startIcon={<FileExcelOutline />}
          >
            {t('productionDeviceReport.export')}
          </DownloadButton>
          {canCreateProductionDevices(loginUser, { organizationId: loginUser.organizationId! }) && (
            <Button
              sx={{ height: '50px' }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleCreateProductionDevice}
            >
              {t('productionDevice.add')}
            </Button>
          )}
        </FlexRow>
      </FlexRow>
      {EcertTableDeferred}
    </FlexColumn>
  )
}
