import { TableBody, TableCell, TableRow } from '@mui/material'
import { getValue } from '../../utils/utils'
import React, { ReactNode } from 'react'
import { IColumn, IVirtualScrollSettings } from './EcertTable'
import { NavLink } from 'react-router-dom'
import StyleGuide from '../../styles/StyleGuide'

type ViewMode = 'EDIT' | 'VIEW'

interface IEcertTableBodyProps {
  virtualizedScrollSettings: IVirtualScrollSettings
  columns: IColumn[]
  tableData: any[]
  onRowClick?: (row: any) => void
  mode?: ViewMode
  rowBackgroundColorFn?: (data: any) => string
}

export default function EcertTableBody({
  virtualizedScrollSettings,
  columns,
  tableData,
  onRowClick,
  mode = 'VIEW',
  rowBackgroundColorFn
}: IEcertTableBodyProps) {
  const handleRowClick = (data: any) => {
    if (!onRowClick) return
    return () => {
      onRowClick(data)
    }
  }

  interface ICellProps {
    role?: string
    accessibilityHrefFn: ((data: any) => string | null) | undefined
    data: any
    accessor: string | ((data: any, index?: number) => any)
    i: number
    j: number
    editable: boolean | undefined
    transformFn: ((data: any) => React.ReactNode) | undefined
    whenCreating?: (data: any, index: number) => React.ReactNode | undefined
    whenEditing?: (data: any, index: number) => React.ReactNode | undefined
  }

  const Cell = ({
    role = 'cell',
    accessibilityHrefFn,
    data,
    accessor,
    transformFn,
    i,
    whenCreating,
    whenEditing
  }: ICellProps) => {
    let cellData: string | ReactNode
    const accessedValue = typeof accessor === 'string' ? getValue(data, accessor) || '' : accessor.call(null, data, i)

    if (mode === 'VIEW') {
      cellData = transformFn ? transformFn.call(null, accessedValue) : accessedValue
    }

    if (mode === 'EDIT') {
      if (whenEditing) {
        cellData = (whenEditing as any).call(null, accessedValue, i)
      }

      if (!whenEditing && accessedValue !== null)
        cellData = transformFn ? transformFn.call(null, accessedValue) : accessedValue

      if ((whenCreating && !data) || (whenCreating && Object.keys(data).length === 0)) {
        cellData = (whenCreating as any).call(null, data, i)
      }
    }

    const linkTarget = accessibilityHrefFn ? accessibilityHrefFn.call(null, data) : null

    return (
      <TableCell role={role} sx={rowBackgroundColorFn ? { backgroundColor: 'transparent' } : {}}>
        {linkTarget ? (
          <NavLink
            onClick={(e) => e.stopPropagation()}
            to={linkTarget}
            style={{ color: StyleGuide.constants.COLOR_BRAND }}
          >
            {cellData}
          </NavLink>
        ) : (
          cellData
        )}
      </TableCell>
    )
  }

  return (
    <TableBody
      sx={{
        backgroundColor:
          tableData.length <= 0 ? StyleGuide.constants.COLOR_SECONDARY_LIGHT_4 : StyleGuide.constants.COLOR_WHITE
      }}
    >
      {tableData.slice(0, virtualizedScrollSettings.rowsToDisplay).map((data, i) => {
        return (
          <TableRow
            hover
            key={i}
            className={onRowClick ? 'clickable' : ''}
            onClick={onRowClick ? handleRowClick(data) : undefined}
            sx={rowBackgroundColorFn ? { backgroundColor: rowBackgroundColorFn(data) } : {}}
          >
            {columns.map(
              ({ accessor, transformFn, accessibilityHrefFn, whenEditingFn, whenCreatingFn, editable, role }, j) => {
                return (
                  <Cell
                    role={role}
                    key={`${i}-${j}`}
                    accessibilityHrefFn={accessibilityHrefFn}
                    i={i}
                    j={j}
                    data={data}
                    transformFn={transformFn}
                    whenEditing={whenEditingFn}
                    accessor={accessor}
                    whenCreating={whenCreatingFn}
                    editable={editable}
                  />
                )
              }
            )}
          </TableRow>
        )
      })}
    </TableBody>
  )
}
