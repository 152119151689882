import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { useTranslation } from 'react-i18next'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useEffect, useState } from 'react'
import { ECERT_API } from '../../../services/ecert-api'
import { useNavigate, useParams } from 'react-router-dom'
import {
  CertificateBundleDto,
  TransactionWithFullDetailsDto,
  TransactionWithFullDetailsDtoType
} from '../../../api/types'
import dayjs from 'dayjs'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import StyleGuide from '../../../styles/StyleGuide'
import { EcertRoutes } from '../../../ecert-routes'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import DownloadButton from '../../../features/buttons/DownloadButton'
import { Edit } from '@mui/icons-material'
import { useAppDispatch } from '../../../hooks'
import { selectCertificatesUpToAmount } from '../account-utils'
import { parseDate } from '../../../utils/utils'
import { canCancelCertificates, canTransferCertificates, isAdmin } from '../../../utils/permission-utils'
import { InfoPopper } from '../../../features/info-popper/InfoPopper'
import { useLoginUser } from '../../../LoginContext'
import CancellationRollbackConfirmDialogButton from './CancellationRollbackConfirmDialog'
import { InfoBox } from '../../../features/info-box/InfoBox'
import { sortByDate } from '../../../utils/date-utils'

export default function OrganizationAccountTransaction() {
  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const [transaction, setTransaction] = useState<TransactionWithFullDetailsDto | null>(null)
  const dispatch = useAppDispatch()
  const loginUser = useLoginUser()

  useEffect(() => {
    document.title = `Ecert - ${t('title.transaction')}`
  }, [t])

  useEffect(() => {
    ECERT_API.getTransaction(+params.id!, +params.accountId!, +params.transactionId!).then((response) => {
      setTransaction(response.data)
    })
  }, [params])

  useEffect(() => {
    ECERT_API.getTransaction(+params.id!, +params.accountId!, +params.transactionId!).then((response) => {
      setTransaction(response.data)
    })
  }, [params, navigate])

  const accordionHeaderText = (bundle: CertificateBundleDto) => {
    return `${bundle.amount} ${t('correction.amountUnit')}
     - ${dayjs(bundle.certificateProductionPeriodEnd).format('MM/YYYY')} 
     - ${bundle.productionDeviceName} (${bundle.productionDeviceGsrn}) 
     - ${bundle.certificateFuelTypeName}`
  }

  const BundleInfoTable = ({ bundle }: { bundle: CertificateBundleDto }) => {
    return (
      <Table sx={{ bgcolor: 'background.paper' }}>
        <TableBody>
          <TableRow>
            <TableCell variant="head">{t('bundle.amount')}</TableCell>
            <TableCell variant="body">{bundle.amount}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('bundle.range')}</TableCell>
            <TableCell variant="body">
              <Box>{bundle.numberRangeStart}</Box>
              <Box>{bundle.numberRangeEnd}</Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('bundle.productionMonth')}</TableCell>
            <TableCell variant="body">{dayjs(bundle.certificateProductionPeriodEnd).format('MM/YYYY')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('bundle.productionTime')}</TableCell>
            <TableCell variant="body">
              {dayjs(bundle.certificateProductionPeriodStart).format('DD.MM.YYYY')} -{' '}
              {dayjs(bundle.certificateProductionPeriodEnd).format('DD.MM.YYYY')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('bundle.certificateFuelType')}</TableCell>
            <TableCell variant="body">
              {`${bundle.certificateFuelTypeName} (${bundle.certificateFuelTypeCode})`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('bundle.natures')}</TableCell>
            <TableCell variant="body">{bundle.certificateNatures}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('bundle.labels')}</TableCell>
            <TableCell variant="body">{bundle.certificateLabelCodes?.join(' ')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('bundle.investmentSupport')}</TableCell>
            <TableCell variant="body">
              <Box>
                <Typography>
                  {(bundle.certificateInvestmentSupportDescriptions?.length || 0) > 0
                    ? t('bundle.hasSupport')
                    : t('bundle.hasNoSupport')}
                </Typography>
              </Box>
              <List sx={{ listStyleType: 'disc', pl: 2 }}>
                {bundle.certificateInvestmentSupportDescriptions?.map((desc, index) => (
                  <ListItem key={index} sx={{ display: 'list-item' }}>
                    {desc}
                  </ListItem>
                ))}
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('bundle.productionSupport')}</TableCell>
            <TableCell variant="body">
              <Box>
                <Typography>
                  {(bundle.certificateProductionSupportDescriptions?.length || 0) > 0
                    ? t('bundle.hasSupport')
                    : t('bundle.hasNoSupport')}
                </Typography>
              </Box>
              <List sx={{ listStyleType: 'disc', pl: 2 }}>
                {bundle.certificateProductionSupportDescriptions?.map((desc, index) => (
                  <ListItem key={index} sx={{ display: 'list-item' }}>
                    {desc}
                  </ListItem>
                ))}
              </List>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const IssuingInfoTable = ({ bundle }: { bundle: CertificateBundleDto }) => {
    return (
      <Table sx={{ bgcolor: 'background.paper' }}>
        <TableBody>
          <TableRow>
            <TableCell variant="head">{t('bundle.issuingDate')}</TableCell>
            <TableCell variant="body">{dayjs(bundle.certificateIssuingDate).format('DD.MM.YYYY')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('bundle.issuingCountry')}</TableCell>
            <TableCell variant="body">{bundle.certificateIssuingCountry}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('bundle.issuingBody')}</TableCell>
            <TableCell variant="body">{`${bundle.certificateIssuingBodyName} (${bundle.certificateIssuingBodyCode})`}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('bundle.competentAuthority')}</TableCell>
            <TableCell variant="body">{bundle.certificateCompetentAuthorities?.join(', ')}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const ProductionDeviceInfoTable = ({ bundle }: { bundle: CertificateBundleDto }) => {
    return (
      <Table sx={{ bgcolor: 'background.paper' }}>
        <TableBody>
          <TableRow>
            <TableCell variant="head">{t('bundle.productionDeviceName')}</TableCell>
            <TableCell variant="body">{bundle.productionDeviceName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('bundle.productionDeviceGsrn')}</TableCell>
            <TableCell variant="body">{bundle.productionDeviceGsrn}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('bundle.productionDeviceDateOfCommissioning')}</TableCell>
            <TableCell variant="body">
              {dayjs(bundle.productionDeviceDateOfCommissioning).format('DD.MM.YYYY')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('bundle.productionDeviceAddress')}</TableCell>
            <TableCell variant="body">
              {[bundle.productionDeviceRegion, bundle.productionDevicePostNumber, bundle.productionDeviceCountry]
                .filter((value) => value)
                .join(' ')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('bundle.productionDeviceCoordinates')}</TableCell>
            <TableCell variant="body">
              {bundle.productionDeviceCoordinates
                ? `${bundle.productionDeviceCoordinateSystem}: ${bundle.productionDeviceLatitude}, ${bundle.productionDeviceLongitude}`
                : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('bundle.productionDeviceCapacity')}</TableCell>
            <TableCell variant="body">{bundle.productionDeviceCapacity}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('bundle.productionDeviceTechnology')}</TableCell>
            <TableCell variant="body">
              {`${bundle.productionDeviceTechnologyName} (${bundle.productionDeviceTechnologyCode})`}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const TransactionInfoTable = () => {
    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant="head">{t('account.transactionId')}</TableCell>
            <TableCell variant="body">{transaction?.id}</TableCell>
          </TableRow>
          {transaction?.externalTransferState ? (
            <TableRow>
              <TableCell variant="head">{t('transaction.externalTransferState')}</TableCell>
              <TableCell variant="body">
                <FlexRow gap="5px">
                  {t(`transaction.externalTransferStateType.${transaction?.externalTransferState}`)}
                  <InfoPopper
                    text={t(`transaction.externalTransferStateDescription.${transaction.externalTransferState}`)}
                  />
                </FlexRow>
              </TableCell>
            </TableRow>
          ) : null}
          <TableRow>
            <TableCell variant="head">{t('transactionReport.time')}</TableCell>
            <TableCell variant="body">{dayjs(transaction?.time).format('DD.MM.YYYY HH:mm')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('account.transactionUser')}</TableCell>
            <TableCell variant="body">{transaction?.userFullName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('account.transactionType')}</TableCell>
            <TableCell variant="body">{t(`transaction.transactionType.${transaction?.type}`)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('account.participant')}</TableCell>
            <TableCell variant="body">{transaction?.participant}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('account.participantAccountNumber')}</TableCell>
            <TableCell variant="body">{transaction?.participantAccountNumber}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('transactionReport.results.amount')}</TableCell>
            <TableCell variant="body">{`${transaction?.sign}${transaction?.processedCertificateAmount}`}</TableCell>
          </TableRow>
          {transaction?.transferDetailsRecipientName && (
            <TableRow>
              <TableCell variant="head">{t('transaction.recipientName')}</TableCell>
              <TableCell variant="body">{transaction?.transferDetailsRecipientName}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell variant="head">{t('transaction.additionalDetails')}</TableCell>
            <TableCell variant="body">{transaction?.additionalDetails}</TableCell>
          </TableRow>
          {transaction?.rollbackTargetTransactionId && (
            <TableRow>
              <TableCell variant="head">{t('transaction.rollbackedTransaction')}</TableCell>
              <TableCell variant="body">
                <Button
                  variant="text"
                  onClick={() => {
                    navigate(
                      EcertRoutes.organizationAccountTransaction(
                        params.id,
                        params.accountId,
                        transaction.rollbackTargetTransactionId
                      )
                    )
                  }}
                  style={{ color: StyleGuide.constants.COLOR_BRAND }}
                >
                  {`${t(`transaction.transactionType.${transaction.rollbackTargetTransactionType}`)} - ${dayjs(
                    transaction.rollbackTargetTransactionTime
                  ).format('DD.MM.YYYY hh:mm')}`}
                </Button>
              </TableCell>
            </TableRow>
          )}

          {transaction?.rollbacked && (
            <TableRow>
              <TableCell variant="head">{t('transaction.rollbackTransaction')}</TableCell>
              <TableCell variant="body">
                <Button
                  variant="text"
                  onClick={() => {
                    navigate(
                      EcertRoutes.organizationAccountTransaction(
                        params.id,
                        params.accountId,
                        transaction.rollbackTransactionId
                      )
                    )
                  }}
                  style={{ color: StyleGuide.constants.COLOR_BRAND }}
                >
                  {`${t(`transaction.transactionType.${transaction.rollbackTransactionType}`)} - ${dayjs(
                    transaction.rollbackTransactionTime
                  ).format('DD.MM.YYYY hh:mm')}`}
                </Button>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    )
  }

  const TransactionCancellationInfoTable = () => {
    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell variant="head">{t('transaction.cancellationUsage')}</TableCell>
            <TableCell variant="body">
              {transaction?.cancellationDetailsConversion ? 'Conversion' : 'Disclosure'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">
              {t(
                transaction?.cancellationDetailsConversion
                  ? 'transaction.conversionRegistry'
                  : 'transaction.beneficiaryType'
              )}
            </TableCell>
            <TableCell variant="body">
              {t(
                transaction?.cancellationDetailsConversion
                  ? `transaction.conversionRegistryEnum.${transaction.cancellationDetailsConversionRegistry}`
                  : `transaction.beneficiaryTypeEnum.${transaction?.cancellationDetailsBeneficiaryType}`
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">
              {t(
                transaction?.cancellationDetailsConversion
                  ? 'transaction.conversionDeviceGsrn'
                  : 'transaction.beneficiaryName'
              )}
            </TableCell>
            <TableCell variant="body">
              {transaction?.cancellationDetailsConversion
                ? transaction.cancellationDetailsConversionDeviceGsrn
                : transaction?.cancellationDetailsBeneficiaryName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">
              {t(
                transaction?.cancellationDetailsConversion
                  ? 'transaction.conversionDeviceName'
                  : 'transaction.beneficiaryCode'
              )}
            </TableCell>
            <TableCell variant="body">
              {transaction?.cancellationDetailsConversion
                ? transaction.cancellationDetailsConversionDeviceName
                : transaction?.cancellationDetailsBeneficiaryCode}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('transaction.consumptionPeriod')}</TableCell>
            <TableCell variant="body">
              {dayjs(transaction?.cancellationDetailsConsumptionPeriodStart).format('DD.MM.YYYY')}
              <span> - </span>
              {dayjs(transaction?.cancellationDetailsConsumptionPeriodEnd).format('DD.MM.YYYY')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">{t('transaction.countryOfConsumption')}</TableCell>
            <TableCell variant="body">{transaction?.cancellationDetailsCountryOfConsumption}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const BundleContent = ({ bundle }: { bundle: CertificateBundleDto }) => {
    return (
      <FlexRow alignItems="flex-start" gap="10px">
        <BundleInfoTable bundle={bundle} />
        <IssuingInfoTable bundle={bundle} />
        <ProductionDeviceInfoTable bundle={bundle} />
      </FlexRow>
    )
  }

  const ReturnButton = () => {
    const returnToPreviousPage = () => {
      navigate(-1)
    }

    return (
      <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={returnToPreviousPage}>
        {t('transaction.return')}
      </Button>
    )
  }

  const handleTransfer = () => {
    ECERT_API.getTransactionRemainingBundles(+params.id!, +params.accountId!, transaction!.id!).then((response) => {
      navigate(EcertRoutes.organizationAccount(params.id, params.accountId))
      selectCertificatesUpToAmount(t, transaction?.processedCertificateAmount!, dispatch, response.data, 'TRANSFER')
    })
  }

  const handleCancel = () => {
    ECERT_API.getTransactionRemainingBundles(+params.id!, +params.accountId!, transaction!.id!).then((response) => {
      navigate(EcertRoutes.organizationAccount(params.id, params.accountId))
      selectCertificatesUpToAmount(t, transaction?.processedCertificateAmount!, dispatch, response.data, 'CANCEL')
    })
  }

  const handleEditCancellationInfo = () => {
    navigate(EcertRoutes.transactionEdit(params.id, params.accountId, params.transactionId))
  }

  const getProperUiOperations = () => {
    if (!transaction) {
      return
    }

    const transferBtn = (
      <Button onClick={handleTransfer} variant="outlined" startIcon={<ArrowForwardIcon />}>
        {t('transaction.transferTransactionBundles')}
      </Button>
    )

    const cancelBtn = (
      <Button onClick={handleCancel} variant="outlined" startIcon={<ArrowForwardIcon />}>
        {t('transaction.cancelTransactionBundles')}
      </Button>
    )

    const downloadStatementBtn = (
      <DownloadButton
        disabled={
          !(
            transaction.type == 'TRANSFER' ||
            (transaction.type == 'EXTERNAL_TRANSFER' && transaction.externalTransferState == 'SENDING_COMPLETED')
          )
        }
        variant="contained"
        startIcon={<DownloadOutlinedIcon />}
        fileName={`transactionStatement_${params.transactionId}`}
        fileUrl={ECERT_API.FILE_DOWNLOAD_URLS.TRANSACTION_STATEMENT(
          params.id!,
          params.accountId!,
          params.transactionId!
        )}
      >
        {t('transfer.export.button')}
      </DownloadButton>
    )

    const editCancellationInfoBtn = (
      <Button onClick={handleEditCancellationInfo} variant="outlined" startIcon={<Edit />} sx={{ height: '50px' }}>
        {t('transaction.editCancellationDetails')}
      </Button>
    )

    const rollbackCancellationBtn = (
      <CancellationRollbackConfirmDialogButton disabled={!transaction.removable || transaction.rollbacked} />
    )

    const downloadCancellationStatementBtn = (
      <FlexColumn>
        <DownloadButton
          variant="contained"
          startIcon={<DownloadOutlinedIcon />}
          fileUrl={ECERT_API.FILE_DOWNLOAD_URLS.TRANSACTION_STATEMENT(
            params.id!,
            params.accountId!,
            params.transactionId!
          )}
          fileName={`transactionStatement_${params.transactionId}`}
        >
          {transaction.statementSignedAt ? t('cancellation.export.signed') : t('cancellation.export.unsigned')}
        </DownloadButton>
        {transaction.statementSignedAt && (
          <Typography alignSelf="flex-end" marginTop="5px">
            {`${t('cancellation.export.signedAt')} ${parseDate(transaction.statementSignedAt).format('DD.MM.YYYY')}`}
          </Typography>
        )}
      </FlexColumn>
    )

    switch (transaction.type) {
      case TransactionWithFullDetailsDtoType.ISSUING:
      case TransactionWithFullDetailsDtoType.CANCELLATION_ROLLBACK:
      case TransactionWithFullDetailsDtoType.EXTERNAL_TRANSFER_ROLLBACK:
        return (
          <>
            {canTransferCertificates(loginUser, params) && <>{transferBtn}</>}
            {canCancelCertificates(loginUser, params) && <>{cancelBtn}</>}
          </>
        )
      case TransactionWithFullDetailsDtoType.TRANSFER:
        if (transaction.sign === '-') {
          return <>{downloadStatementBtn}</>
        }
        if (transaction.sign === '+') {
          return (
            <>
              {canTransferCertificates(loginUser, params) && <>{transferBtn}</>}
              {canCancelCertificates(loginUser, params) && <>{cancelBtn}</>}
              {downloadStatementBtn}
            </>
          )
        }
        return <></>
      case TransactionWithFullDetailsDtoType.CANCELLATION:
        return (
          <>
            {isAdmin(loginUser) && (
              <>
                {!transaction.rollbacked && editCancellationInfoBtn}
                {rollbackCancellationBtn}
              </>
            )}
            {!transaction.rollbacked && downloadCancellationStatementBtn}
          </>
        )
      case TransactionWithFullDetailsDtoType.CORRECTION:
        return <></>
      case TransactionWithFullDetailsDtoType.EXTERNAL_TRANSFER:
        return <>{downloadStatementBtn}</>
      case TransactionWithFullDetailsDtoType.EXPIRATION:
        return <></>
      default:
        throw Error(`Unknown transaction type: ${transaction.type}`)
    }
  }

  return (
    <FlexColumn gap="20px">
      <FlexRow>
        <ReturnButton />
      </FlexRow>
      <FlexRow>
        <Typography variant="h1">
          {t(!transaction?.rollbacked ? 'transaction.title' : 'transaction.titleRollbacked')}
        </Typography>
      </FlexRow>
      {transaction && transaction.type == 'CANCELLATION' && (!transaction.removable || transaction.rollbacked) && (
        <FlexRow>
          <InfoBox type="INFO">{t('transaction.rollbackCancellationInfo')}</InfoBox>
        </FlexRow>
      )}
      <FlexColumn gap="10px">
        <FlexRow gap="10px" justifyContent="flex-end">
          {getProperUiOperations()}
        </FlexRow>
        <FlexRow gap="50px">
          <FlexColumn gap="5px" flexGrow={1}>
            <Typography variant="h2">{t('transaction.generalInfo')}</Typography>
            <TransactionInfoTable />
          </FlexColumn>
          {transaction?.type === TransactionWithFullDetailsDtoType.CANCELLATION && (
            <FlexColumn gap="5px" flexGrow={1}>
              <Typography variant="h2">{t('transaction.cancellationInfo')}</Typography>
              <TransactionCancellationInfoTable />
            </FlexColumn>
          )}
        </FlexRow>
      </FlexColumn>
      <FlexColumn gap="10px">
        <Typography variant="h2">{t('transaction.certificates')}</Typography>
        {transaction?.bundles
          ?.sort((a, b) => sortByDate(a.certificateProductionPeriodEnd!, b.certificateProductionPeriodEnd!))
          .map((bundle, index) => (
            <Accordion key={bundle.id} square>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: StyleGuide.constants.COLOR_BRAND, order: -1 }} />}
                aria-controls={`bundle-${index}-content`}
                id={`bundle-${index}`}
              >
                {accordionHeaderText(bundle)}
              </AccordionSummary>
              <AccordionDetails>
                <BundleContent bundle={bundle} />
              </AccordionDetails>
            </Accordion>
          ))}
      </FlexColumn>
      <FlexRow>
        <ReturnButton />
      </FlexRow>
    </FlexColumn>
  )
}
