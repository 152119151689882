/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type FeeTypeDtoProductCode = typeof FeeTypeDtoProductCode[keyof typeof FeeTypeDtoProductCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeeTypeDtoProductCode = {
  ALKUE: 'ALKUE',
  ALKUM: 'ALKUM',
  ALKUT: 'ALKUT',
  ALKUV: 'ALKUV',
  ALKUTV: 'ALKUTV',
  ALKUVR: 'ALKUVR',
  ALKUTO: 'ALKUTO',
  ALKUMU: 'ALKUMU',
} as const;
