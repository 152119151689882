/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * OpenAPI definition
 * OpenAPI spec version: v0
 */

export type FeeTypeDtoName = typeof FeeTypeDtoName[keyof typeof FeeTypeDtoName];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeeTypeDtoName = {
  ANNUAL_ACCOUNT_FEE: 'ANNUAL_ACCOUNT_FEE',
  ANNUAL_PRODUCTION_DEVICE_FEE: 'ANNUAL_PRODUCTION_DEVICE_FEE',
  NEW_PRODUCTION_DEVICE: 'NEW_PRODUCTION_DEVICE',
  ISSUING_PRICE_PER_MWH: 'ISSUING_PRICE_PER_MWH',
  ISSUING_REFUND_MWH: 'ISSUING_REFUND_MWH',
  IMPORT_PRICE_PER_MWH: 'IMPORT_PRICE_PER_MWH',
  IMPORT_REFUND_MWH: 'IMPORT_REFUND_MWH',
  EXPORT_PRICE_PER_MWH: 'EXPORT_PRICE_PER_MWH',
  EXTERNAL_CANCELLATION_PRICE_PER_MWH: 'EXTERNAL_CANCELLATION_PRICE_PER_MWH',
  EECS_CERTIFICATION: 'EECS_CERTIFICATION',
  OTHER_WORK_PER_HOUR: 'OTHER_WORK_PER_HOUR',
  FREE_TEXT_AND_AMOUNT: 'FREE_TEXT_AND_AMOUNT',
} as const;
