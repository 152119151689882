import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button, TextField } from '@mui/material'
import FeeTypeSelect from './FeeTypeSelect'
import {
  FeeTypeDto,
  FeeTypeDtoName,
  FeeTypeDtoProductCode,
  InvoiceRowDto,
  InvoiceRowDtoType
} from '../../../../api/types'
import ProductionDeviceSelectDialog from './ProductionDeviceSelectDialog'
import ListIcon from '@mui/icons-material/List'
import { useTranslation } from 'react-i18next'
import ProductCodeSelect from './ProductCodeSelect'
import { DeviceFilter } from './ProductionDeviceSelectTable'

interface InvoiceEditRowProps {
  row: InvoiceRowDto
  deviceFilter: DeviceFilter
  customFeeTypes: FeeTypeDto[]
  onChange: (newValue: InvoiceRowDto) => void
}

export function InvoiceEditRow({ row, deviceFilter, customFeeTypes, onChange }: InvoiceEditRowProps) {
  const [invoiceRow, setInvoiceRow] = useState<InvoiceRowDto>(row)
  const { t } = useTranslation()
  const [deviceDialogOpen, setDeviceDialogOpen] = useState(false)

  const handleFeeTypeChange = (newValue: FeeTypeDto) => {
    const newType = newValue.name as InvoiceRowDtoType
    setInvoiceRow((prevState) => ({
      id: prevState.id,
      type: newType,
      amount: newType === FeeTypeDtoName.ANNUAL_ACCOUNT_FEE ? 1 : undefined
    }))
  }

  const handleFieldChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInvoiceRow((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value as InvoiceRowDtoType
    }))
  }

  const handleOnProductCodeSelect = (newValue: FeeTypeDtoProductCode) => {
    setInvoiceRow((prevState) => ({
      ...prevState,
      productCode: newValue
    }))
  }

  const handleProductionDeviceSelect = (devices: number[]) => {
    setInvoiceRow((prevState) => ({
      ...prevState,
      deviceIds: devices,
      amount: devices.length
    }))
  }

  useEffect(() => {
    onChange(invoiceRow)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceRow])

  const OpenModalButton = () => {
    return (
      <Button onClick={() => setDeviceDialogOpen(true)} variant="contained" startIcon={<ListIcon />}>
        {t('invoiceDetailsReport.selectDevicesTitle')}
      </Button>
    )
  }

  const ReasonAndAmountTextFields = () => {
    return (
      <>
        <TextField
          fullWidth
          name="reason"
          label="Syötä selite"
          defaultValue={invoiceRow.reason}
          onChange={handleFieldChange}
        />
        <TextField
          name="amount"
          label="lkm / h"
          type="number"
          defaultValue={invoiceRow.amount}
          onChange={handleFieldChange}
        />
      </>
    )
  }

  const GetInputs = useMemo(() => {
    switch (invoiceRow?.type) {
      case FeeTypeDtoName.EECS_CERTIFICATION:
        return <OpenModalButton />
      case FeeTypeDtoName.OTHER_WORK_PER_HOUR:
        return <ReasonAndAmountTextFields />
      case FeeTypeDtoName.ANNUAL_ACCOUNT_FEE:
        return <></>
      case FeeTypeDtoName.FREE_TEXT_AND_AMOUNT:
        return (
          <Box sx={{ display: 'flex', flex: '1', gap: '16px' }}>
            <ProductCodeSelect value={invoiceRow.productCode} onChange={handleOnProductCodeSelect} />
            <ReasonAndAmountTextFields />
          </Box>
        )
      case FeeTypeDtoName.NEW_PRODUCTION_DEVICE:
        return <OpenModalButton />
      case FeeTypeDtoName.ANNUAL_PRODUCTION_DEVICE_FEE:
        return <OpenModalButton />
      default:
        return <></>
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceRow.type, invoiceRow.productCode])

  return (
    <Box sx={{ display: 'flex', gap: '16px', flex: 'auto' }}>
      <FeeTypeSelect
        customFeeTypeOptions={customFeeTypes}
        onChangeSelect={handleFeeTypeChange}
        valueToShow={invoiceRow?.type}
      />
      {GetInputs}
      {deviceDialogOpen && (
        <ProductionDeviceSelectDialog
          deviceIds={invoiceRow.deviceIds ? invoiceRow.deviceIds : []}
          deviceFilter={deviceFilter}
          toggleModal={() => setDeviceDialogOpen(!deviceDialogOpen)}
          onConfirm={handleProductionDeviceSelect}
        />
      )}
    </Box>
  )
}
