import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useEffect, useState } from 'react'
import { FeeTypeDtoProductCode } from '../../../../api/types'

interface IProductCodeSelectProps {
  value: FeeTypeDtoProductCode | undefined
  onChange: (newValue: FeeTypeDtoProductCode) => void
}

export default function ProductCodeSelect({ value, onChange }: IProductCodeSelectProps) {
  const [selectedCode, setSelectedCode] = useState<FeeTypeDtoProductCode>(value ?? FeeTypeDtoProductCode.ALKUE)

  const handleOnChange = (event: SelectChangeEvent) => {
    setSelectedCode(event.target.value as FeeTypeDtoProductCode)
  }

  useEffect(() => {
    onChange(selectedCode)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCode])

  const items: FeeTypeDtoProductCode[] = [
    FeeTypeDtoProductCode.ALKUE,
    FeeTypeDtoProductCode.ALKUM,
    FeeTypeDtoProductCode.ALKUMU,
    FeeTypeDtoProductCode.ALKUT,
    FeeTypeDtoProductCode.ALKUTO,
    FeeTypeDtoProductCode.ALKUTV,
    FeeTypeDtoProductCode.ALKUV,
    FeeTypeDtoProductCode.ALKUVR
  ]

  return (
    <Select
      fullWidth
      labelId="organization-select"
      defaultValue={selectedCode}
      value={selectedCode}
      onChange={handleOnChange}
      sx={{ marginBottom: '15px', maxWidth: '240px' }}
    >
      {items.map((e) => {
        return (
          <MenuItem key={e} value={e}>
            {e}
          </MenuItem>
        )
      })}
    </Select>
  )
}
